import Api from "@/services/Index"


const getAll = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/major/quotas",config)
}
const detail = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/major/quotas/"+id)
}
const store = (formData)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/major/quotas",formData)
}
const update = (id,formData)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/double/major/quotas/"+id,formData)
}
const del = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/double/major/quotas/"+id)
}

export default {
    getAll,detail,store,update,del,
}
